import { t } from "i18next";
import { FC, useState } from "react";
import { useSelector } from "react-redux";

// Services and interfaces
import { selectCoachId } from "@/store/coach";
import { selectHasTeam, selectIsOwner } from "@/store/team";
import { selectTotalActiveClients } from "@/repositories/client";
import { useGetUpcomingInvoiceQuery } from "@/repositories/invoice";
import { selectPaymentsCancelled, selectPaymentsEnabled, selectDowngradePending, selectSubscriptionType, selectEndsAt, selectIsTrial } from "@/store/subscription";

// Styles
import { CircleDash, CircleFill, Undo, Upgrade } from "@carbon/icons-react";

// Components
import Link from "@/components/link";
import Tooltip from "@/components/tooltip";
import { Box, Card, Skeleton } from "@mui/material";
import CancelDowngradeModal from "./cancel_downgrade_modal";
import InvoiceBreakdownModal from "./invoice_breakdown_modal";
import ChangeSubscriptionModal from "./change_subscription_modal";


const BillingHeader: FC = () => {

    const ends_at = useSelector(selectEndsAt);
    const isTrial = useSelector(selectIsTrial);
    const hasTeam = useSelector(selectHasTeam);
    const coachId = useSelector(selectCoachId);
    const isTeamOwner = useSelector(selectIsOwner);
    const plan = useSelector(selectSubscriptionType);
    const paymentModuleEnabled = useSelector(selectPaymentsEnabled);
    const paymentsModuleCancelled = useSelector(selectPaymentsCancelled);
    const pendingDowngrade = useSelector(selectDowngradePending);
    const {data: invoice, isLoading} = useGetUpcomingInvoiceQuery(undefined);
    const totalActiveClients = useSelector((state) => selectTotalActiveClients(state, hasTeam ? coachId : undefined));

    const [open, setOpen] = useState<string|null>(null);
    const nextInvoiceDate = invoice ? new Date(invoice.date).toLocaleDateString() : '-';
    const invoiceTotal = invoice?.total ? `£${invoice?.total/100}` : '-';

    return (
        <>
            {/* Title */}
            <span className="heading-07-compact">{t('pages.settings.billing.paymentSummary')}</span>
            <Box height={8} />

            {/* Summary Card */}
            <Card elevation={0} sx={{padding: '24px 32px', border: 'solid 1px var(--border-subtle-01)', borderRadius: '6px'}}>

                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 1fr">

                    {/* Current Plan */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">

                        <span className="label-01">{t('pages.settings.billing.yourPlan')}</span>
                        {isLoading ? <Skeleton variant="text" width={90} height={20} /> : 
                            <Box display="flex" alignItems="center">
                                <span className="heading-07">{hasTeam && !isTeamOwner ? t('enums.subscriptionPlan.team', {plan: plan}) : t(`enums.subscriptionPlan.${isTrial ? `${plan}Trial` : plan}`)}</span>
                                {pendingDowngrade && <Tooltip kind="nav" title="Downgrade pending">
                                        <CircleFill size="12" style={{color: 'var(--support-warning)', marginLeft: '4px'}} />
                                    </Tooltip>}
                            </Box>}
                        {isLoading ? <Skeleton variant="text" width={115} height={18} /> : 
                            (!isTrial && <Link   
                                size="small"
                                label={t(`components.links.${pendingDowngrade ? 'cancelDowngrade' : 'changePlan'}`)}
                                icon={pendingDowngrade ? <Undo /> : <Upgrade />}
                                disabled={!!ends_at}
                                onClick={pendingDowngrade ? () => setOpen('cancel_downgrade') : () => setOpen('change_plan')}
                                />)}
                            {open == 'change_plan' && <ChangeSubscriptionModal
                                open={open == 'change_plan'}
                                onClose={() => setOpen(null)} 
                                />}
                            {open == 'cancel_downgrade' && <CancelDowngradeModal
                                open={open == 'cancel_downgrade'}
                                onClose={() => setOpen(null)}
                                />}

                    </Box>

                    {/* Next Payment */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">

                        <span className="label-01">{t('pages.settings.billing.nextPayment')}</span>
                        {isLoading ? <Skeleton variant="text" width={90} height={20} /> : 
                            <span className="heading-07">{hasTeam && !isTeamOwner ? t('pages.settings.billing.managedByTeam') : nextInvoiceDate}</span>}

                    </Box>

                    {/* Next Invoice */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">
                        <span className="label-01">{t('pages.settings.billing.nextInvoice')}</span>
                        {isLoading ? <Skeleton variant="text" width={70} height={20} /> : 
                            <span className="heading-07">{invoiceTotal}</span>}
                        {isLoading ? <Skeleton variant="text" width={115} height={18} /> : 
                            <Link
                                size="small"
                                label={t('components.links.seeBreakdown')}
                                onClick={invoice ? () => setOpen('breakdown') : undefined}
                                />}
                            {invoice && open == 'breakdown' && <InvoiceBreakdownModal 
                                open={open == 'breakdown'} 
                                onClose={() => setOpen(null)} 
                                invoice={invoice} 
                                />}
                    </Box>

                    {/* Clients */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">
                        <span className="label-01">{t('pages.settings.billing.clients')}</span>
                        {isLoading ? <Skeleton variant="text" width={30} height={20} /> : 
                            <span className="heading-07">{hasTeam && !isTeamOwner ? t('pages.settings.billing.managedByTeam') : totalActiveClients}</span>}
                    </Box>

                    {/* Payments & Business */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">
                        <span className="label-01">{t('pages.settings.billing.paymentsEnabled')}</span>
                        {isLoading ? <Skeleton variant="text" width={30} height={20} /> : 
                            <Box display="flex" alignItems="center">
                                {paymentModuleEnabled ?
                                    <CircleFill style={{color: paymentsModuleCancelled ? 'var(--support-warning)' : 'var(--teal-60)'}} /> :
                                    <CircleDash />}
                                <Box width="4px" />
                                {paymentModuleEnabled ? 
                                    <span className="heading-07">{t(`general.${paymentsModuleCancelled ? 'cancelled' : 'on'}`)}</span> :
                                    <span className="body-02" style={{color: 'var(--text-placeholder)'}}>{t('general.off')}</span>}
                            </Box>}
                    </Box>

                </Box>
            </Card>
        </>
    )
}

export default BillingHeader;