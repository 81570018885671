import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AddressMode } from "@stripe/stripe-js";
import { AddressElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectHasTrialCode } from "@/store/coach";
import { Plan } from "@/interfaces/subscription/subscription";
import { useCreateSubscriptionMutation } from "@/repositories/subscription";
import { ISubscriptionForm } from "@/interfaces/subscription/subscription_form";

// Styles
import { ShoppingCartPlus } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material"
import Button from '@/components/button';
import FullScreenLoader from "@/components/overlays/fullscreen_loader";
import SubscriptionOptions from "@/components/billing/subscription_options";


const SubscriptionForm = () => {

    const { t } = useTranslation();
    const elements = useElements();
    const stripe = useStripe();

    const isTrial = useSelector(selectHasTrialCode);
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<Plan>('standard');
    const trialEndDate = dayjs().add(7, 'day').format('D MMMM YYYY');
    const [createSubscription, { isLoading }] = useCreateSubscriptionMutation();

    const [addressComplete, setAddressComplete] = useState(false);
    const [paymentComplete, setPaymentComplete] = useState(false);

    const billingInterval = (): string => {
        switch (selectedPlan) {
            case 'standard':
                return 'month';
            case 'enterprise':
                return 'month';
            case 'enterprise_annual':
                return 'year';
            default:
                return 'month';
        }
    }

    const billingAmount = (): number => {
        switch (selectedPlan) {
            case 'standard':
                return 40;
            case 'enterprise':
                return 225;
            case 'enterprise_annual':
                return 2100;
            default:
                return 40;
        }
    }
 
    const addressOptions = {
        mode: 'billing' as AddressMode
    }

    const submit = async () => {
        setLoading(true);
        if (!elements || !stripe) return;

        const address = elements.getElement(AddressElement);
        const payment = elements.getElement(PaymentElement);

        if (!address || !payment) return;
        
        await elements.submit();

        const { paymentMethod, error } = await stripe.createPaymentMethod({elements: elements});
        if (error) {
            setLoading(false);
            return showToast({type: 'info', title: t('notifications.billing.paymentFailed'), message: error.message});
        }

        const payload = {
            payment_method_id: paymentMethod.id,
            plan: selectedPlan
        } as ISubscriptionForm;
        
        await createSubscription(payload).unwrap().catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })

        setLoading(false);
            
    }

    return (
        <>
        <FullScreenLoader show={loading || isLoading} loadingText={t(`billing.${isTrial ? 'processingTrial' : 'processingPayment'}`)} />
        <Box 
            display="grid" 
            gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }} 
            columnGap="24px" 
            rowGap="32px" 
            maxWidth="1536px" 
            justifyContent="space-between"
            marginBottom="40px"
            >
            
            {/* Left column */}
            <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start" maxWidth="540px">

                {/* Plan selection */}
                <SubscriptionOptions
                    selected={selectedPlan}
                    onChange={(value) => setSelectedPlan(value)}
                    />

                {/* Spacer */}
                <Box height="24px" />

                {/* Address element */}
                <span className="heading-06" style={{color: 'var(--text-secondary)'}}>{t('pages.setup.subscription.form.billingAddress')}</span>
                <Box height="16px" />
                <AddressElement options={addressOptions} onChange={(e) => setAddressComplete(e.complete)} />
            
            </Box>

            {/* Right column */}
            <Box display="flex" flexDirection="column" alignItems="flex-start" maxWidth="540px">

                {/* Payment element */}
                <span className="heading-06" style={{color: 'var(--text-secondary)'}}>{t('pages.setup.subscription.form.paymentDetails')}</span>
                <Box height="16px" />
                <PaymentElement
                    options={{
                        terms: {
                            card: 'never'
                        }
                    }}
                    onChange={(e) => setPaymentComplete(e.complete)} 
                    />
                <Box height="30px" />

                {/* Total due */}
                <Box display="flex" justifyContent="space-between" width="100%" marginBottom="30px">
                    <span className="heading-06-compact">
                        {t(isTrial ? 'pages.setup.subscription.form.totalDueTrial' : 'pages.setup.subscription.form.totalDue', {date: trialEndDate})}
                    </span>
                    <span className="heading-06-compact">£{billingAmount()}{selectedPlan == 'standard' && isTrial ? '*' : ''}</span>
                </Box>

                {isTrial ? 
                        <span className="body-02" style={{color: 'var(--text-secondary)', whiteSpace: 'pre-wrap', textAlign: 'left'}}>{
                            t('pages.setup.subscription.form.trialTerms', {
                                context: `${selectedPlan == 'standard'}`, 
                                date: trialEndDate, 
                                duration: '7 day', 
                                amount: billingAmount(),
                                interval: billingInterval()
                            })}</span> :
                        <span className="body-02" style={{color: 'var(--text-secondary)', whiteSpace: 'pre-wrap', textAlign: 'left'}}>{t('pages.setup.subscription.form.terms', {amount: billingAmount()})}</span>}

                {/* Actions */}
                <Box display="flex" width="100%" justifyContent="flex-end" marginTop="24px">
                    <Button
                        disabled={!addressComplete || !paymentComplete || loading || isLoading}
                        loading={null}
                        endIcon={<ShoppingCartPlus />}
                        onClick={submit}
                        sx={{'@media (max-width: 768px)': {width: '100%'}}}
                        >
                        {t('components.buttons.subscribe')}
                    </Button>
                </Box>
            </Box>
                
        </Box>

        </>
    )
}

export default SubscriptionForm;